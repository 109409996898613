body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar{
  width: 3px;
}

::-webkit-scrollbar-thumb{
  background-color: #48b1e2;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-transition: background-color 200ms;
  -moz-transition: background-color 200ms;
  -ms-transition: background-color 200ms;
  -o-transition: background-color 200ms;
  transition: background-color 200ms;
}

::-webkit-scrollbar-thumb:hover{
  background-color: #9f325f;
}

.date-picker > .form-control{
  background: #F0F0F0;
  color: #767983;
  padding: 8px 8px 8px 24px;
  font-size: 16px;
  font-family: Lato,sans-serif;
  height: 36px;
  border:2px solid transparent;
}
.date-picker > .form-control :hover, .date-picker > .form-control :active{
  border: 1px solid #6396D8;
  outline: none;
}
